import { Injectable } from '@angular/core';
import { ApplicationDataService } from '@application/application.service';
import { Environment, PlaidLenders } from '@environment/environment';
import { lastValueFrom, Observable, Subject } from 'rxjs';
import { PlaidApiService } from './plaid-api.service';
import {
  PlaidErrorCodes,
  PlaidEventMetaData,
  PlaidEventName,
  PlaidEventRequest,
  PlaidSuccessMetaData
} from './plaid.types';
import { PlaidObj, PlaidStatic, PlaidSuccessObject } from './plaid.types';
import { AppInsightsService } from '../app-insights/app-insights.service';
import { ApplicationData } from '@application/application';
import { GoogleAnalytics } from '../google-analytics/googleanalytics.service';
import { BankConnectionApi } from '@application/connect-bank/connect-bank-api.service';

@Injectable({
  providedIn: 'root'
})
export class PlaidService {
  private plaid: PlaidObj;
  private plaidStatic: PlaidStatic = window['Plaid'];
  private successSubject = new Subject<PlaidSuccessObject>();
  private application: ApplicationData;
  private excludedErrorCodes = [
    PlaidErrorCodes.INSUFFICIENT_CREDENTIALS,
    PlaidErrorCodes.INVALID_CREDENTIALS,
    PlaidErrorCodes.INVALID_MFA
  ];

  public failedIntializeCall = false;

  constructor(
    private env: Environment,
    private plaidApi: PlaidApiService,
    private applicationDataService: ApplicationDataService,
    private appInsightsService: AppInsightsService,
    private googleAnalytics: GoogleAnalytics,
    private bankConnectionApi: BankConnectionApi
  ) {}

  public async initializePlaid(): Promise<void> {
    this.application = this.applicationDataService.getApplication();
    const lenderCode = this.application.product.lenderCode;
    const lenderConfiguration = this.env.plaid.lenders.find(
      (lender: PlaidLenders) => lender.lenderCode == lenderCode
    );
    const products = this.env.plaid.products;
    const { clientName, environment, linkCustomizationName } = lenderConfiguration;
    const provider = this.application.product.bankDataProvider.toUpperCase();

    this.failedIntializeCall = false;

    //track lender code to GA
    this.googleAnalytics.setGoogleTagManagerVariables({ lenderCode: lenderCode });
    try {
      const linkToken = await lastValueFrom(
        this.plaidApi.getLinkToken(
          lenderCode,
          clientName,
          products,
          linkCustomizationName ?? '',
          provider,
          '',
          this.env.brand
        )
      );

      this.plaid = this.plaidStatic.create({
        token: linkToken,
        env: environment,
        onSuccess: this.onPlaidSuccess.bind(this),
        onLoad: this.setPlaidIframeAttributes.bind(this),
        onEvent: this.onPlaidEvent.bind(this)
      });
    } catch {
      this.failedIntializeCall = true;
      setTimeout(() => window.scrollTo(0, 0));
    }
  }

  /* istanbul ignore next */
  private onPlaidSuccess(
    publicToken: string,
    metaData: PlaidSuccessMetaData
  ): void {
    this.successSubject.next({
      publicToken,
      metaData
    });
  }

  public open(): void {
    this.plaid.open();
  }

  public successSubscription(): Observable<PlaidSuccessObject> {
    return this.successSubject.asObservable();
  }

  public destroy(): void {
    if (this.plaid) {
      this.plaid.destroy();
      this.successSubject = new Subject<PlaidSuccessObject>();
    }
  }

  public setPlaidIframeAttributes(): void {
    document.querySelectorAll('iframe').forEach((iframe: HTMLIFrameElement) => {
      if (iframe.id?.startsWith('plaid-link-iframe-')) {
        const privateAttribute = document.createAttribute('data-private');
        iframe.setAttributeNode(privateAttribute);
        const titleAttribute = document.createAttribute('title');
        titleAttribute.value = "plaid-link-iframe";
        iframe.setAttributeNode(titleAttribute);
      }
    });
  }

  private onPlaidEvent(eventName: string, metaData: PlaidEventMetaData): void {
    const isCodeExcluded = this.excludedErrorCodes.includes(
      metaData.error_code
    );

    metaData.amsAppId = this.application.id;
    metaData.appSequenceId = this.application.sequenceApplicationId;

    this.appInsightsService.trackEvent(eventName, metaData);

    this.bankConnectionApi
      .postPlaidEvent(this.createPlaidEventRequest(eventName, metaData))
      .subscribe();

    if (eventName !== PlaidEventName.SEARCH_INSTITUTION) {
      this.googleAnalytics.formInteractionEvent({
        bankDataProvider_event: `plaid_${eventName}`,
        bankDataProvider: 'plaid'
      });
    }

    if (eventName === 'ERROR' && !isCodeExcluded) {
      this.bankConnectionApi
        .postPlaidErrorCode({
          code: metaData.error_code
        })
        .subscribe(
          () => {},
          () => {}
        );
    }
  }

  private createPlaidEventRequest(
    eventName: string,
    metaData: PlaidEventMetaData
  ): PlaidEventRequest {
    const plaidEvent: PlaidEventRequest = new PlaidEventRequest();
    plaidEvent.provider = "Plaid";
    plaidEvent.eventType = eventName;
    plaidEvent.timeStamp = metaData.timestamp;
    plaidEvent.data = metaData;
    
    return plaidEvent;
  }
}
