<ecl-notification-box
  type="error"
  id="bankConnectionErrorMessage"
  *ngIf="handleBankConnectionResponseService.bankConnectionError"
>
  {{ bankConnectionError }}
</ecl-notification-box>

<ecl-notification-box
  type="error"
  id="achRoutingErrorMessage"
  *ngIf="handleBankConnectionResponseService.achRoutingError"
>
  {{ achErrorFinicity }}
</ecl-notification-box>

<ecl-notification-box
  type="error"
  id="accountNotPermittedErrorMessage"
  *ngIf="handleBankConnectionResponseService.accountNotPermittedError"
>
  {{ accountNotPermittedError }}
</ecl-notification-box>
<ecl-notification-box
  type="error"
  id="failedServerCallErrorMessage"
  *ngIf="
    handleBankConnectionResponseService.failedServerCallError ||
    finicityService.failedIntializeCall ||
    plaidService.failedIntializeCall
  "
>
  We apologize for the inconvenience but we have encountered a system error.
  Please contact us at 888-225-0080 if you need immediate assistance, or please
  try again.
</ecl-notification-box>

<div
  *ngIf="!newDesign"
  id="connectBankPageText"
  [innerHtml]="pageTextContent"
  class="connect-bank-text-content"
  aria-labelledby="connectBankPageText"
></div>

<ecl-accordion-group
  *ngIf="!newDesign"
  [config]="groupAccordionConfig"
></ecl-accordion-group>

<p
  id="connectBankSectionTitle"
  class="connect-bank-section-title ecl-font-section-header-color-medium"
>
  {{ subSectionTitle }}
</p>
<form *ngIf="!newDesign" [formGroup]="form" class="connect-bank-form-container">
  <ecl-radio-group
    [formControlConfig]="connectBankOptionConfig"
    formControlName="connectBankOption"
  >
    <ecl-radio-button
      [formControlConfig]="connectBankOptionConfig.buttons[0]"
      class="connect-bank-radio-button"
      googleAnalyticsFocusOut="connectBankBankDataProviderOption"
    ></ecl-radio-button>

    <ecl-radio-button
      [formControlConfig]="connectBankOptionConfig.buttons[1]"
      class="connect-bank-radio-button"
      googleAnalyticsFocusOut="connectBankManualBankInformationOption"
    ></ecl-radio-button>
  </ecl-radio-group>
</form>
<form
  *ngIf="newDesign"
  [formGroup]="form"
  class="new-connect-bank-form-container"
>
  <ecl-radio-group
    class="radio-group-connect-bank"
    [formControlConfig]="connectBankOptionConfig"
    formControlName="connectBankOption"
  >
    <div class="div-class-radio" *ngIf="executeRadioOptionOrderAbTest">
      <ecl-radio-button
        [formControlConfig]="connectBankOptionConfig.buttons[1]"
        class="new-connect-bank-radio-button"
        googleAnalyticsFocusOut="connectBankManualBankInformationOption"
      ></ecl-radio-button>
      <div [innerHtml]="newDesign.manualOption.detailText | safeHtml"></div>
    </div>

    <div class="div-class-radio">
      <ecl-radio-button
        [formControlConfig]="connectBankOptionConfig.buttons[0]"
        class="new-connect-bank-radio-button"
        googleAnalyticsFocusOut="connectBankBankDataProviderOption"
      ></ecl-radio-button>
      <div
        [innerHtml]="newDesign.connectBankOption.detailText | safeHtml"
      ></div>
    </div>
    <div class="div-class-radio" *ngIf="!executeRadioOptionOrderAbTest">
      <ecl-radio-button
        [formControlConfig]="connectBankOptionConfig.buttons[1]"
        class="new-connect-bank-radio-button"
        googleAnalyticsFocusOut="connectBankManualBankInformationOption"
      ></ecl-radio-button>
      <div [innerHtml]="newDesign.manualOption.detailText | safeHtml"></div>
    </div>
  </ecl-radio-group>
</form>
<app-consents
  #consentsComponent
  [formGroup]="form"
  [consentSectionItems]="consentsSection"
></app-consents>
<div
  *ngIf="newDesign"
  id="newConnectBankPageText"
  [innerHtml]="pageTextContent"
  class="connect-bank-text-content"
></div>
<section aria-label="Connect Bank Continue Button">
  <div
    [ngClass]="
      newDesign
        ? 'new-connect-bank-continue-button-container'
        : 'connect-bank-continue-button-container'
    "
  >
    <button
      focusOnError
      type="submit"
      (click)="submitConnectBank()"
      id="connectBankContinueButton"
      googleAnalyticsClick="BankInformation-Continue"
      class="ecl-button-primary"
    >
      {{ continueButton }}
    </button>
  </div>
</section>

<div *ngIf="newDesign" class="connect-bank-form-accordion">
  <ecl-accordion-group
    [config]="groupAccordionConfig"
    (expandAccordion)="expand($event)"
    (collapseAccordion)="collapse($event)"
  ></ecl-accordion-group>
</div>
<app-debug-hud>
  <button (click)="connectBankDebugHudService.setInactivityTime()">
    Set Idle to 15 sec
  </button>
  <button (click)="connectBankDebugHudService.resetInactivityTime()">
    Set Idle to default
  </button>
  <button *ngIf="formContainsConsents" (click)="debugHudSubmitConsents()">
    Submit Consents
  </button>
</app-debug-hud>
