import { Injectable } from '@angular/core';
import { ApplicationFlow } from '@application/application';
import {
  PaymentOptionItem,
  PaymentOptionsResponse
} from '@application/underwriting-decisions/choose-your-terms/choose-your-terms.model';
import { LoanPaymentApiService } from './loan-api.service';
import { lastValueFrom } from 'rxjs';

export interface LoanPaymentInitializationData {
  loanAmount: number;
  apr: number;
  requestedAmount: number;
  applicationFlow: ApplicationFlow;
  originationFee?: number;
}

export interface LoanAmountMinMax {
  minimum: number;
  maximum: number;
}

export interface PaymentOptionsState {
  currentSelection: number;
  paymentOptions: PaymentOptionItem[];
}

export interface SelectedPaymentOption {
  fundAmount: number;
  originationFee: number;
  paymentOptions: PaymentOptionItem[];
}

@Injectable({ providedIn: 'root' })
export class LoanPaymentDataService {
  public apr: number;
  public loanAmountMinMax: LoanAmountMinMax = {
    minimum: 0,
    maximum: 0
  };
  public selectedPaymentOption: PaymentOptionItem;
  public allPaymentOptions: PaymentOptionItem[];
  constructor(private loanPaymentApiService: LoanPaymentApiService) {}

  public async initializeLoanAmount(
    initializationData: LoanPaymentInitializationData
  ): Promise<void> {
    this.loanAmountMinMax.maximum = initializationData.loanAmount;
    this.apr = initializationData.apr;
    await this.loadDefaultPaymentOptions(initializationData.loanAmount);
  }

  public async loadDefaultPaymentOptions(
    loanAmount: number,
    originationFee: number = null
  ): Promise<void> {
    await lastValueFrom(
      this.loanPaymentApiService.getPaymentOptions(
        loanAmount,
        'Default',
        originationFee
      )
    ).then((response: PaymentOptionsResponse) => {
      this.loanAmountMinMax.minimum = response.minLineAmount;
      this.selectedPaymentOption = response.paymentOptions[0];
  
    });
  }

  public async loadAdditionalOptions(
    loanAmount: number,
    originationFee: number = null
  ): Promise<void> {
    await lastValueFrom(
      this.loanPaymentApiService.getPaymentOptions(
        loanAmount,
        'All',
        originationFee
      )
    ).then((response: PaymentOptionsResponse) => {

      let options = response.paymentOptions;

      this.allPaymentOptions = [
        ...new Map(options.map(item => [item['signature'], item])).values()
      ];
    });
  }
}
