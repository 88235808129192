import { Injectable } from '@angular/core';
import { Environment } from '@environment/environment';
import Cookies from 'js-cookie';

export enum CookieEnums {
  ResumeByAppId = 'resumeByAppId',
  ga = '_ga',
  gpcSessionId = 'gpcSessionId',
  gcid = 'gcid'
}

@Injectable({ providedIn: 'root' })
export class CookieService {
  constructor(private environment: Environment) {}
  public set(
    key: CookieEnums,
    value: string,
    options: Cookies.CookieAttributes
  ): void {
    options.secure = options.domain !== 'localhost';

    if (options.domain === 'localhost') {
      delete options.domain;
    }
    if (key == CookieEnums.gcid) {
      const gcidCookieName = this.getCookieString(CookieEnums.gcid.toString());
      Cookies.set(gcidCookieName, value, options);
    } else {
      Cookies.set(key, value, options);
    }
  }

  public get(key: CookieEnums): string {
    return Cookies.get(key);
  }

  public remove(key: CookieEnums): void {
    Cookies.remove(key);
  }

  public getSessionId(): string {
    const cookieString = this.getCookieString(
      CookieEnums.gpcSessionId.toString()
    );

    const sessionData = this.get(cookieString);

    return sessionData ? sessionData.split('.')[2] : '';
  }

  public getCookieValue(key: CookieEnums): string {
    const cookieString = this.getCookieString(key.toString());

    const cookieData = this.get(cookieString);

    return cookieData;
  }

  private getCookieString(cookieName: string) {
    return this.environment.cookie[cookieName];
  }
}
