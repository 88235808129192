import {
  Component,
  OnInit,
  SecurityContext,
  ViewChild,
  ViewEncapsulation,
  AfterViewInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ECCustomDateAdapter,
  ECCustomDateParserFormatter
} from '@core/datepicker/EDCustomDatepickerAdapter.service';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import { GettingStartedFormGroup } from '../getting-started.form';
import { GettingStartedValidator } from '../getting-started.validator';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  ReapplyApiService,
  ReapplyResponse,
  ReapplyStartOptionsResponse
} from '@core/reapply/reapply-api.service';
import {
  GettingStartedBaseComponent,
  GettingStartedPageBase
} from '../getting-started-base.component';
import { StateMessageService } from '@core/state-message/state-message-service';
import { OnDestroy } from '@angular/core';
import { LoadingModalService } from '../../loading-modal/loading-modal.service';
import { ApplicationApi, StartOption } from '../../application.api';
import { ResumeModalComponent } from '../resume-modal/resume-modal.component';
import { GettingStartedService } from '../getting-started-service';
import { Environment } from '@environment/environment';
import { ApplicationFlow, ApplicationForm } from '@application/application';
import { ApplicationDataService } from '../../application.service';
import * as deepDiff from 'deep-diff';
import {
  SessionStorageKeys,
  SessionStorageService
} from '@core/session-storage/session-storage.service';
import moment from 'moment';
import {
  GoogleAnalytics,
  GoogleTagManagerVariables
} from '@core/google-analytics/googleanalytics.service';
import { UserInactivityStatusService } from '@core/inactivity-module/user-inactivity.service';
import { StateEligibilityService } from '../state-eligibility/state-eligibility.service';
import { AppInsightsService } from '@core/app-insights/app-insights.service';
import { CmsGettingStarted } from '../getting-started.content';
import { ConsentsComponent } from '@application/consents/consents.component';
import { TodayCardHandleService } from '@core/brand-handle-services/today-card-handle.service';
import {
  lastValueFrom,
  Subscription,
  distinctUntilChanged,
  pairwise,
  take
} from 'rxjs';
import { ConfigurationService } from '@core/configuration/configuration.service';
import { NeuroIdService } from '@core/neuro-id/neuro-id.service';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import {
  LogRocketService,
  AMSAppSessionCorrelator
} from '@core/logrocket/logrocket.service';
import { CookieService, CookieEnums } from '@core/cookie/cookie-service';
import { ApplicationFlowService } from '@core/application-flow/application-flow.service';
import { CurrencyPipe } from '@angular/common';
import { CookieAttributes } from 'js-cookie';

@Component({
  templateUrl: '../getting-started.component.html',
  styleUrls: ['../getting-started.component.scss'],
  providers: [
    GettingStartedFormGroup,
    GettingStartedValidator,
    {
      provide: NgbDateAdapter,
      useClass: ECCustomDateAdapter
    },
    {
      provide: NgbDateParserFormatter,
      useClass: ECCustomDateParserFormatter
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class ReapplyComponent extends GettingStartedBaseComponent
  implements OnInit, OnDestroy, GettingStartedPageBase, AfterViewInit {
  @ViewChild('consentsComponent') private consentsComponent: ConsentsComponent;
  @ViewChild('consentToCommunicationComponent')
  private consentToCommunicationComponent: ConsentsComponent;
  private nextPage: string;
  public pageSubTitle: string;
  public pageContent: SafeHtml;
  public showDebugHud = '';
  public recaptchaIsHidden = true;
  public initialEditApplicationData: ApplicationForm;
  public isEditMode = false;
  public isPartnerPrePop = false;
  public mockMode = false;
  public reapplyData;
  public formChangesSubscription: Subscription;
  public gcid: string;
  public gcidCookieValue: string;

  constructor(
    public form: GettingStartedFormGroup,
    public route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public stateMessageService: StateMessageService,
    public modalService: NgbModal,
    private reapplyApiService: ReapplyApiService,
    private router: Router,
    private loadingModalService: LoadingModalService,
    private gettingStartedService: GettingStartedService,
    public environment: Environment,
    private applicationDataService: ApplicationDataService,
    private sessionStorageService: SessionStorageService,
    private applicationApi: ApplicationApi,
    public googleAnalytics: GoogleAnalytics,
    private userAppStarted: UserInactivityStatusService,
    private neuroIdService: NeuroIdService,
    public validators: GettingStartedValidator,
    public stateEligibilityService: StateEligibilityService,
    public appinsightsService: AppInsightsService,
    public todayCardHandleService: TodayCardHandleService,
    public configurationService: ConfigurationService,
    private cmsPageContentService: CmsPageContentService,
    private logrocketService: LogRocketService,
    private cookieService: CookieService,
    public currencyPipe: CurrencyPipe,
    public applicationFlowService: ApplicationFlowService
  ) {
    super(
      form,
      modalService,
      stateMessageService,
      validators,
      stateEligibilityService,
      googleAnalytics,
      appinsightsService,
      environment,
      route,
      todayCardHandleService,
      configurationService,
      currencyPipe
    );
    this.mockMode =
      this.router.getCurrentNavigation()?.extras.state?.mock || false;
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.loadingModalService.open();
    this.initialEditApplicationData = this.applicationDataService.getApplication().form;

    this.isEditMode = this.initialEditApplicationData?.applicant?.identity
      ?.socialSecurityNumber
      ? true
      : false;

    this.gcid = this.cookieService.getCookieValue(CookieEnums.gcid);

    const gtagData: GoogleTagManagerVariables = {
      gcid: this.gcid
    };

    this.googleAnalytics.setGoogleTagManagerVariables(gtagData, true);

    this.sessionStorageService.setItem(
      SessionStorageKeys.applicationGCID,
      this.gcid
    );

    let reapplyData: ReapplyResponse;
    this.cmsPageContent = this.route.snapshot.data.cmsContent.prequalificationReapply.gettingStartedCommon;
    this.cmsPageContentService.updatePageTitle(this.cmsPageContent.header);
    this.form.build(this.cmsPageContent.validationMessages);
    const dateRangeMessage = this.cmsPageContent.validationMessages.dateOfBirth
      .otherErrorMessage;
    this.formConfig.dobConfig.errorDateRange = dateRangeMessage
      ? dateRangeMessage
      : this.formConfig.dobConfig.errorDateRange;
    this.pageSubTitle = this.cmsPageContent.subHeader;
    this.pageContent = this.sanitizer.sanitize(
      SecurityContext.HTML,
      this.cmsPageContent.content
    );
    this.googleAnalytics.setOneTimeGlobalData();

    if (this.isEditMode) {
      this.editAppPrepopulate();
      return;
    }

    if (this.mockMode) {
      this.reapplyData = this.mockPopulate();
      this.prepoPulateReapplyData(this.reapplyData);

      this.updateGoogleTagManagerVariables();
      this.googleAnalytics.formViewEvent();
      return;
    }

    try {
      this.reapplyData = await lastValueFrom(
        this.reapplyApiService.getReapplyInfo()
      );

      if (this.reapplyData) {
        this.prepoPulateReapplyData(this.reapplyData);
      }
      this.userAppStarted.setUserApplicationStatus(true);

      this.updateGoogleTagManagerVariables();
      this.googleAnalytics.formViewEvent();
    } catch (error) {
      this.router.navigate(['getting-started']);
    }
    this.nextPage = this.getContinueRoute();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this.form.get('ssn').enable();
    this.form.get('email').enable();

    if (this.formChangesSubscription != null) {
      this.formChangesSubscription.unsubscribe();
    }
  }

  public prepoPulateReapplyData(reapplyData: ReapplyResponse): void {
    this.form.patchValue({
      firstname: reapplyData.firstName,
      lastname: reapplyData.lastName,
      suffix: reapplyData.nameSuffix,
      dob: reapplyData.dateOfBirth,
      ssn: `55555${reapplyData.ssn}`,
      addressLine1: reapplyData.addressLine1,
      addressLine2: reapplyData.addressLine2,
      city: reapplyData.city,
      state: reapplyData.stateCode,
      postalCode: reapplyData.zipCode,
      mobile: reapplyData.phoneNumber.replace(/\D/g, ''),
      email: reapplyData.emailAddress
    });
    this.onStateUpdate(reapplyData.stateCode);
    this.form.get('dob').markAsTouched({ onlySelf: true });
    this.formConfig.emailConfig.disabled = 'true';
    this.formConfig.ssnConfig.disabled = 'true';
    this.loadingModalService.close();
  }

  public editAppPrepopulate(): void {
    const applicant = this.initialEditApplicationData.applicant;
    const last4 = applicant.identity.socialSecurityNumber.slice(-4);

    // date picker requires delay
    setTimeout(() => {
      this.form.patchValue({
        firstname: applicant.identity.firstName,
        lastname: applicant.identity.lastName,
        suffix: applicant.identity.suffix,
        dob: moment(applicant.identity.dateOfBirth, 'YYYY-MM-DD').format(
          'MM/DD/YYYY'
        ),
        ssn: `55555${last4}`,
        addressLine1: applicant.residences[0].address.line1,
        addressLine2: applicant.residences[0].address.line2,
        city: applicant.residences[0].address.city,
        state: applicant.residences[0].address.stateCode,
        postalCode: applicant.residences[0].address.zipCode,
        mobile: applicant.phones[0].number,
        email: applicant.emails[0].address,
        code: this.initialEditApplicationData.enrollmentCode,
        electronicCommunicationAgreement: true
      });
    }, 500);

    this.form.get('ssn').disable();
    this.form.get('email').disable();
    this.loadingModalService.close();
  }
  public async onFocusOut(event: any): Promise<void> {
    this.googleAnalytics.formInteractionEvent({
      step_name: event.target.id
    });
  }

  public onAddressSuggestionError(event: any) {
    this.appInsightsService.trackException(event);
  }

  public async ngAfterViewInit(): Promise<void> {
    this.formChangesSubscription = this.form.valueChanges
      .pipe(
        distinctUntilChanged(),
        pairwise(), // gets a pair of old and new value
        take(1)
      )
      .subscribe(([oldValue, newValue]) => {
        this.googleAnalytics.logFormInteractionEventsForChangedValues(
          oldValue,
          newValue,
          false
        );
      });

    setTimeout(() => {
      const logRocketData: AMSAppSessionCorrelator = {
        appInsightsSessionId: this.appInsightsService.getGoogleTagAppInsightsTelemetry()
          .appInsightsSesssionId,
        ga4SessionId: this.cookieService.getSessionId()
      };
      this.logrocketService.trackAppLogRocketCustomEvent(logRocketData);
      this.gcidCookieValue = this.cookieService.getCookieValue(
        CookieEnums.gcid
      );
      this.gcid = this.gcid ? this.gcid : this.gcidCookieValue;
      const cookieAttributes: CookieAttributes = {
        domain: this.environment.setCookieConfig.gcid.domain,
        expires: this.environment.setCookieConfig.gcid.expiryInDays
      };
      if (this.gcid && this.gcid != this.gcidCookieValue) {
        this.cookieService.set(CookieEnums.gcid, this.gcid, cookieAttributes);
      }
      this.sessionStorageService.setItem(
        SessionStorageKeys.applicationGCID,
        this.gcid
      );

      const gtagData: GoogleTagManagerVariables = {
        logrocket_id: this.cookieService.getSessionId()
      };

      this.googleAnalytics.setGoogleTagManagerVariables(gtagData, true);
    }, 3000);
  }

  public async submit(): Promise<void> {
    this.form.showValidationErrors();

    if (!this.form.valid) {
      return;
    }

    if (super.isStateNotEligible()) {
      return;
    }

    this.loadingModalService.open();

    if (this.isEditMode) {
      this.updateAndNavigate();
      return;
    }

    const startOptionsResponse: ReapplyStartOptionsResponse = await lastValueFrom(
      this.reapplyApiService.startOptions()
    );

    switch (startOptionsResponse.startOptions) {
      case StartOption.StartNewOnly:
        this.createApplication();
        break;
      case StartOption.ResumeOnly:
      case StartOption.ResumeOrStartNew: {
        this.loadingModalService.close();

        const modalRef = this.modalService.open(ResumeModalComponent, {
          backdrop: 'static',
          windowClass: 'resume-modal'
        });

        modalRef.componentInstance.data = {
          ...startOptionsResponse,
          firstName: this.form.value.firstname
        };
        modalRef.componentInstance.htmlContent = this.cmsPageContent.modals.resumeModal;

        modalRef.result.then(data => {
          if (data && !data.resumeApplication) {
            this.createApplication();
          }
        });

        return;
      }
    }
  }

  public async createApplication(): Promise<void> {
    const applicationForm = this.gettingStartedService.buildReapplyCreateRequest(
      this.form.value
    );

    this.appendDisclosuresToFormData(applicationForm);

    const creationResponse = await lastValueFrom(
      this.reapplyApiService.reapplyCreate({
        brand: this.environment.brand,
        applicationFlow: ApplicationFlow.Reapply,
        form: applicationForm
      })
    );

    if (this.form.value.code) {
      this.googleAnalytics.setGoogleTagManagerVariables({
        enrollment_code: this.form.value.code
      });
    }

    this.applicationDataService.replaceApplicationForm({
      form: applicationForm
    });

    this.sessionStorageService.setItem(
      SessionStorageKeys.authorizationToken,
      creationResponse.token
    );
    this.neuroIdService.setVariable('funnel', ApplicationFlow.Reapply);
    this.router.navigate([this.nextPage]);
  }

  private appendDisclosuresToFormData(formData: ApplicationForm): void {
    if (formData.disclosures == null) {
      formData.disclosures = [];
    }

    formData.disclosures = formData.disclosures.concat(
      this.consentToCommunicationComponent?.disclosures
    );
    if (this.consentsComponent) {
      formData.disclosures = formData.disclosures.concat(
        this.consentsComponent?.disclosures
      );
    }
    formData.applicant.electronicCommunicationAgreement = this.consentToCommunicationComponent?.disclosures?.some(
      disc => disc.consentGiven === true
    );
  }

  public async updateAndNavigate(): Promise<void> {
    const applicationForm = this.gettingStartedService.buildReapplyCreateRequest(
      this.form.value
    );
    if (applicationForm.disclosures !== undefined) {
      Array.prototype.push.apply(
        applicationForm.disclosures,
        this.consentsComponent?.disclosures
      );
    } else {
      applicationForm.disclosures = this.consentsComponent?.disclosures;
    }

    const difference = deepDiff(
      this.initialEditApplicationData,
      applicationForm
    );
    this.nextPage = this.getContinueRoute();
    const isThereAnyChangeApartFromSnnAndEmail = difference.some(
      item =>
        item.path[item.path.length - 1] !== 'socialSecurityNumber' &&
        item.path[item.path.length - 1] !== 'emails'
    );

    if (isThereAnyChangeApartFromSnnAndEmail) {
      try {
        await lastValueFrom(this.applicationApi.append(applicationForm));

        this.applicationDataService.replaceApplicationForm({
          form: applicationForm
        });

        this.router.navigate([this.nextPage]);
      } catch (error) {
        this.loadingModalService.close();
        if (error.error.message.includes('is not Serviced')) {
          this.isStateEligible = false;
          this.isStateNotEligible();
        } else {
          this.router.navigate(['error']);
        }
      }
    } else {
      this.router.navigate([this.nextPage]);
    }
  }

  private getContinueRoute(): string {
    return this.applicationFlowService.getContinuePath(
      null,
      ApplicationFlow.Reapply
    );
  }

  private updateGoogleTagManagerVariables(): void {
    const variables: GoogleTagManagerVariables = {
      state: this.form.value.state,
      application_start_date: moment.utc().format(),
      userType: ApplicationFlow.Reapply,
      visitorid: this.appInsightsService.getAppInsightsAccountId(),
      neuroId: this.neuroIdService.neuroIdSessionId,
      full_referral_url: document.referrer
    };
    this.googleAnalytics.setGoogleTagManagerVariables(variables);
  }

  public mockPopulate(): ReapplyResponse {
    const reapplyData: ReapplyResponse = {
      addressLine1: '123 Main St.',
      addressLine2: '',
      city: 'Addison',
      customerId: '1486419',
      customerStatus: 'Active',
      dateOfBirth: '12/31/1989',
      emailAddress: 'test_933235355@elasticcash.net',
      firstName: 'TESTnjzob',
      lastName: 'AUTOnjzob',
      phoneNumber: '(123) 5550126',
      ssn: '5355',
      stateCode: 'TX',
      nameSuffix: 'Sr.',
      zipCode: '75001'
    };
    return reapplyData;
  }
}
