import { CurrencyPipe, DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild, afterRender } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationData, ApplicationForm } from '@application/application';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';
import {
  UnderwritingDecisionEnum,
  UnderwritingDecisionModel
} from '@application/underwriting/underwriting.model';
import { ApplicationFlowService } from '@core/application-flow/application-flow.service';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { DocumentApi } from '@core/document/document.api';
import { DocumentService } from '@core/document/document.service';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';
import { LocationProxy } from '@core/location-proxy/location-proxy.service';
import { TableConfig } from '@elevate/table';
import { CurrencyInput, InputCurrencyComponent, RadioGroup } from '@elevate/ui-components';
import { lastValueFrom, Observable } from 'rxjs';
import moment from 'moment';
import {
  LoanAmountMinMax,
  OriginationFeeConfiguration,
  PaymentFrequencyOption,
  PaymentOptionItem,
  PaymentScheduleItem,
  SelectedTermsModel,
  paymentFrequencyOptions
} from './choose-your-terms.model';
import { ChooseYourTermsForm } from './choose-your-terms.form';
import {
  ValidationMessagesError,
  numericMaxValidator,
  numericMinValidator
} from '@elevate/forms';
import { LoanPaymentDataService } from '@application/underwriting/loan-payment-data.service';
import { ApplicationDataService } from '@application/application.service';
import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ApplicationApi } from '@application/application.api';

@Component({
  selector: 'app-choose-your-terms',
  templateUrl: './choose-your-terms.component.html',
  styleUrls: ['./choose-your-terms.component.scss'],
  providers: [DatePipe, CurrencyPipe]
})
export class ChooseYourTermsComponent implements OnInit, AfterViewInit {
  public loanAmountConfig: CurrencyInput = {
    id: 'loanAmountInput',
    required: 'true',
    notDecimalAllow: true,
    digitSeparator: ',',
    leftAlign: false,
    type: 'number',
    attributes: {
      'data-nid-target': 'loanAmount'
    },    
  };

  public paymentOptionConfig: RadioGroup = {
    direction: 'column',
    value: '',
    buttons: [
      {
        id: ' ',
        name: 'chooseYourTermOptionsRadioGroup',
        label: ' ',
        value: '2900'
      }
    ]
  };

  public content;
  public approvalAmount: string;
  public counterOfferSubText: string;
  public isCounterOffer = false;
  public subHeader: string;
  public form: ChooseYourTermsForm;
  public tableConfig: TableConfig;
  public options: PaymentOptionItem[];
  public originalSelection: number;
  public loanAmount: number;
  public firstName: string;
  public frequency: string;
  public offerType: string;
  public state: string;
  public csoFee: number;
  public loanMinMax: LoanAmountMinMax;
  public selectedSchedule: PaymentOptionItem;
  public apr = 0;
  public interestAmount: number;
  public requestedAmount: number;
  public counterAmount: number;
  public currentPayoff: number;
  public documentName = `noaa_${Date.now()}.pdf`;
  public applicationSubmitOffer: UnderwritingDecisionModel;
  public appPrequalOffer: UnderwritingDecisionModel;
  public showDocumentError = false;
  public isFormerApplication = false;
  public isRefinanceApplication = false;
  public depositAmount: Observable<number>;
  public originationFeePercentage?: number;
  public originationFeeCapDollarAmount: number;
  public isloanOriginationFeeApplicable?: boolean;
  public originationFee?: number;
  public fundedAmount: number;
  public originationFeeConfig: OriginationFeeConfiguration;
  private application: ApplicationData;
  public incrementalAmount: number;
  public tableData: PaymentScheduleItem[];
  extendTable: boolean = false;
  numPayments: number;
  paymentAmount: number;
  inputLoanAmountSubtext: string;
  loanAmountAPR: string;
  public entriesLength = 3;
  public optionsLoading = true;
  public loanAmountLoading = true;
  paymentSchedule: PaymentScheduleItem[];
  firstPaymentDate: string;
  firstPaymentSubText: string;
  selectedPaymentAmount: number;
  buttons = [];
  public continueBtnDisabled = true;
  public changeLoanAmountBtnDisabled = false;
  public paymentScheduleHidden = true;
  @ViewChild('loanAmtEleRef',{ static: false }) public loanAmtEleRef: InputCurrencyComponent;

  constructor(
    private cmsPageContentService: CmsPageContentService,
    private route: ActivatedRoute,
    private documentService: DocumentService,
    public googleAnalytics: GoogleAnalytics,
    private locationProxy: LocationProxy,
    private documentApi: DocumentApi,
    private loadingService: LoadingModalService,
    private applicationApi: ApplicationApi,
    private loanPaymentDataService: LoanPaymentDataService,
    private applicationFlowService: ApplicationFlowService,
    private applicationDataService: ApplicationDataService,
    private router: Router,
    private datepipe: DatePipe,
    private currencyPipe: CurrencyPipe
  ) {
    this.content = this.route.snapshot.data.cmsContent.chooseYourTerms;
    this.application = this.applicationDataService.getApplication();
    this.form = new ChooseYourTermsForm();
    afterRender(() => {
      document.body.style.overflow = 'auto';
    });
  }

  public loanAmountInputKeyup(): void {
    const loanInputValue = this.form.get('loanAmount').value;
    const isLoanAmountChangedAndValid =
      this.loanAmount !== loanInputValue && this.form.valid;
    const isLoanAmountChangedOrNotValid =
      this.loanAmount !== loanInputValue || !this.form.valid;

    this.changeLoanAmountBtnDisabled = isLoanAmountChangedAndValid
      ? false
      : true;
    this.continueBtnDisabled = isLoanAmountChangedOrNotValid ? true : false;
  }

  public async ngOnInit(): Promise<void> {    

    this.applicationSubmitOffer = this.application.underwriting.steps.find(
      (decision: any) =>
        decision.name === 'ApplicationSubmit' && decision.status === 'Complete'
    );

    this.approvalAmount = this.formatLoanAmount(
      this.applicationSubmitOffer.disposition.offer.line
    );
    this.incrementalAmount = this.content.loanAmountBlock.incrementAmount;
    this.cmsPageContentService.updatePageTitle(
      `${this.content.header}`
        .replace(
          '{firstName}',
          this.application.form.applicant.identity.firstName
        )
        .replace('{offerAmount}', this.approvalAmount)
    );

    this.isloanOriginationFeeApplicable = false;

    this.state = this.application.form.applicant.residences[0].address.stateCode;
    if (
      this.applicationSubmitOffer.disposition.type ===
      UnderwritingDecisionEnum.CounterOffer
    ) {
      this.isCounterOffer = true;
      const prequalOffer = this.application.underwriting.steps.find(
        (decision: any) =>
          decision.name === 'Prequal' && decision.status === 'Complete'
      );
      this.counterOfferSubText = `${this.content.counterofferSubtext}`
        .replace(
          '{prevOffer}',
          this.formatLoanAmount(prequalOffer.disposition.offer.line)
        )
        .replace('{offerAmount}', this.approvalAmount)
        .replace(
          '{noaaText}',
          `<a id="noaaDownload" href="javascript:void(0)">${this.content.noaaTextLabel}</a>`
        );
    }

    this.subHeader = this.content.subHeader;

    let incomeFrequency = this.application.form.applicant.income.sources[0]
      .frequency;
    incomeFrequency =
      incomeFrequency !== 'Weekly' ? incomeFrequency : 'BiWeekly';
    this.frequency = paymentFrequencyOptions.filter(
      (option: PaymentFrequencyOption) => {
        return option.value === incomeFrequency;
      }
    )[0].text;

    this.form.patchValue({
      loanAmount: this.applicationSubmitOffer.disposition.offer.line
    });

    this.initializeLoanAmount();
  }

  public ngAfterViewInit(): void {
    if (this.isCounterOffer) {
      document
        .getElementById('noaaDownload')
        .addEventListener('click', this.downloadNoaa);
    }
  }

  public updateSelectedAmount($event: any): void {
    this.googleAnalytics.formInteractionEvent({
      step_name: window.location.pathname,
      field_id: $event.target.id,
      form_submit_text: $event.target.labels[0].innerText
    });
    this.paymentOptionConfig.value = $event.target.value;
    this.form.get('paymentOptions').patchValue(this.paymentOptionConfig.value);
    this.loanPaymentDataService.selectedPaymentOption = this.loanPaymentDataService.allPaymentOptions.filter(
      (optionItem: PaymentOptionItem) => {
        return optionItem.signature === $event.target.value;
      }
    )[0];
    this.updateDisplayValues();
  }

  public async initializeLoanAmount(): Promise<void> {
    this.loanAmount = this.applicationSubmitOffer.disposition.offer.line;
    await this.loanPaymentDataService
      .initializeLoanAmount({
        loanAmount: this.applicationSubmitOffer.disposition.offer.line,
        apr: this.applicationSubmitOffer.disposition.offer.apr,
        requestedAmount: this.application.form.requestedAmount,
        applicationFlow: this.application.applicationFlow
      })
      .then(() => {
        this.form
          .get('loanAmount')
          .addValidators([
            numericMinValidator(
              this.loanPaymentDataService.loanAmountMinMax.minimum,
              this.content.loanAmountBlock.minValidationText
            ),
            numericMaxValidator(
              () => this.loanPaymentDataService.loanAmountMinMax.maximum,
              this.content.loanAmountBlock.maxAmountValidation
            ),
            this.checkIncrementValue(this.incrementalAmount)
          ]);      

        this.handleLoanAmountView();
        this.updatePaymentOptions();
      });
  }

  public handleLoanAmountView() {
    this.inputLoanAmountSubtext = this.stringFormat(
      this.content.loanAmountBlock.inputSubtext
    );

    this.updateDisplayValues();
    this.loanAmountLoading = false;
    setTimeout(()=>{
      this.loanAmtEleRef.setDisabledState(true);
    },0); 
  }

  public updateTableSize(): void {
    this.extendTable = !this.extendTable;
    if (this.extendTable) {
      this.entriesLength = this.numPayments;
      this.tableData = this.paymentSchedule;
      this.googleAnalytics.expandAccordionEvent({
        link_id: 'table-expansion-button',
        link_text: this.content.fullPaymentScheduleText,
        link_classes: 'table-expansion-button',
        link_type: 'accordion',
        link_domain: this.locationProxy.origin,
        link_url: this.locationProxy.href
      });
    } else {
      this.entriesLength = 3;
      this.tableData = this.paymentSchedule.slice(0, 3);
      document
        .getElementsByClassName('header-scroll-target')[0]
        ?.scrollIntoView();
      this.googleAnalytics.collapseAccordionEvent({
        link_id: 'table-expansion-button',
        link_text: this.content.fullPaymentScheduleText,
        link_classes: 'table-expansion-button',
        link_type: 'accordion',
        link_domain: this.locationProxy.origin,
        link_url: this.locationProxy.href
      });
    }
  }

  public async updateLoanAmount(): Promise<void> {
    const loanAmountInput = this.form.get('loanAmount');

    if (this.loanAmount !== loanAmountInput.value && this.form.valid) {
      this.loanAmount = loanAmountInput.value;
      this.loanAmtEleRef.setDisabledState(true);
      this.optionsLoading = true;
      this.continueBtnDisabled = true;
      this.paymentScheduleHidden = true;
      this.buttons = [];
      this.tableData = null;
      await this.loanPaymentDataService
        .loadDefaultPaymentOptions(loanAmountInput.value)
        .then(() => {
          this.updateDisplayValues();
          this.updatePaymentOptions();
        });
    }
  }

  public async updatePaymentOptions(): Promise<void> {
    await this.loanPaymentDataService.loadAdditionalOptions(
      this.form.get('loanAmount').value
    );
    let filteredDefaultOption = this.loanPaymentDataService.allPaymentOptions.filter(
      (paymentOption: PaymentOptionItem) => {
        return (
          this.loanPaymentDataService.selectedPaymentOption.signature ===
          paymentOption.signature
        );
      }
    );

    let filteredOtherOptions = this.loanPaymentDataService.allPaymentOptions.filter(
      (paymentOption: PaymentOptionItem) => {
        return (
          this.loanPaymentDataService.selectedPaymentOption.signature !==
          paymentOption.signature
        );
      }
    );

    filteredOtherOptions = filteredOtherOptions.sort((a, b) => {
      return b.paymentSchedule.length - a.paymentSchedule.length;
    });

    this.buttons.push({
      id: `option1`,
      name: `chooseYourTermOptionsRadioGroup`,
      label: `${this.formatCurrencyAmount(
        filteredDefaultOption[0]?.paymentSchedule[0]?.totalPayment
      )} ${this.frequency} / ${
        filteredDefaultOption[0]?.paymentSchedule?.length
      } Payments`,
      value: `${filteredDefaultOption[0]?.signature}`
    });

    for (let optionNo = 0; optionNo < filteredOtherOptions.length; optionNo++) {
      this.buttons.push({
        id: `option${this.buttons.length + 1}`,
        name: `chooseYourTermOptionsRadioGroup`,
        label: `${this.formatCurrencyAmount(
          filteredOtherOptions[optionNo].paymentSchedule[0].totalPayment
        )} ${this.frequency} / ${
          filteredOtherOptions[optionNo].paymentSchedule.length
        } Payments`,
        value: `${filteredOtherOptions[optionNo].signature}`
      });
    }
    
    this.handlePaymentOptionView();   
  }

  public handlePaymentOptionView() {
    this.paymentOptionConfig.buttons = this.buttons;
    this.paymentOptionConfig.value = this.buttons[0].value;
    this.form.get('paymentOptions').patchValue(this.paymentOptionConfig.value);
    this.optionsLoading = false;
    this.continueBtnDisabled = false;
    this.changeLoanAmountBtnDisabled = false;
    this.paymentScheduleHidden = false;
    this.loanAmtEleRef.setDisabledState(false);
  }

  public updateDisplayValues(): void {
    let selectionOption = this.loanPaymentDataService.selectedPaymentOption;

    this.selectedPaymentAmount =
      selectionOption.paymentSchedule[0].totalPayment;
    this.apr =
      selectionOption.paymentSchedule.length > 0 ? selectionOption.tilaApr : 0;
    this.firstPaymentDate =
      selectionOption.paymentSchedule.length > 0
        ? this.formatDate(selectionOption.paymentSchedule[0].dueDate)
        : '';
    this.paymentSchedule = selectionOption.paymentSchedule;

    this.numPayments = selectionOption.paymentSchedule.length;

    this.tableData = this.paymentSchedule.slice(0, 3);

    this.extendTable = false;
    this.tableConfig = this.setTableConfig(
      this.content.paymentScheduleBlock.paymentTable
    );

    this.firstPaymentSubText = this.stringFormat(
      this.content.paymentScheduleBlock.subtext
    );

    this.loanAmountAPR = this.stringFormat(
      this.content.loanAmountBlock.buttonSubtext
    );
  }

  public setTableConfig(columns: any): TableConfig {
    let tableConfiguration: TableConfig = {
      hideMobileView: true,
      actions: [],
      columns: []
    };

    for (let columnNo = 0; columnNo < columns.length; columnNo++) {
      tableConfiguration.columns[columnNo] = {
        name: columns[columnNo].propertyName,
        label: columns[columnNo].columnHeader,
        mobileLabel: columns[columnNo].columnHeader,
        type: columns[columnNo].typeSelection,
        UTCTimeZone: true
      };
    }

    return tableConfiguration;
  }

  public downloadNoaa = async (): Promise<void> => {
    const documentName = `noaa_${Date.now()}.pdf`;
    const blob: Blob = await lastValueFrom(this.documentApi.get('noaa'));
    this.documentService.download(blob, documentName);
  };

  public async onSubmit(): Promise<void> {
    this.form.showValidationErrors();
    if (!this.form.valid) {
      return;
    }
    this.googleAnalytics.setGoogleTagManagerVariables({
      loan_date: moment.utc(this.applicationSubmitOffer.requestedAt).format(),
      loan_first_payment_date: this.firstPaymentDate,
      loan_amount: this.loanAmount,
      loan_apr: this.apr
    });
    this.loadingService.open();
    this.originationFee = this.originationFee || 0;

    const paymentSelection: SelectedTermsModel = {
      fundAmount: this.loanAmount - this.originationFee,
      loanAmount: this.loanAmount,
      apr: this.apr,
      originationFee: this.originationFee,
      paymentOption: this.loanPaymentDataService.selectedPaymentOption
    };
    const application: ApplicationForm = {
      selectedTerms: paymentSelection
    };
    const nextPath = this.applicationFlowService.getContinuePath();

    await lastValueFrom(this.applicationApi.append(application)).then(() => {
      this.router.navigate([nextPath]);
    });
  }

  private formatLoanAmount(amount: number): string {
    let formattedAmount = this.currencyPipe.transform(
      amount,
      '$',
      'symbol',
      '1.0-0'
    );
    return formattedAmount;
  }

  private formatCurrencyAmount(amount: number): string {
    let formattedAmount = this.currencyPipe.transform(
      amount,
      '$',
      'symbol',
      '1.2-2'
    );
    return formattedAmount;
  }

  private formatDate(date: string): string {
    let formattedDateInUtc = this.datepipe.transform(date, 'MM/dd/yyyy', 'utc');

    return formattedDateInUtc;
  }

  private stringFormat(stringToFormat: string): string {
    let mapObj = {
      minAmount: this.formatLoanAmount(
        this.loanPaymentDataService.loanAmountMinMax?.minimum
      ),
      maxAmount: this.formatLoanAmount(
        this.loanPaymentDataService.loanAmountMinMax?.maximum
      ),
      incrementAmount: `$${this.incrementalAmount}`,
      aprPrecentage: this.apr,
      firstPayDate: `${this.firstPaymentDate}`
    };
    var regEx = new RegExp(Object.keys(mapObj).join('|'), 'gi');
    return stringToFormat
      .replace(regEx, function(matched) {
        return mapObj[matched];
      })
      .replace(/([{}])/g, '');
  }

  private checkIncrementValue(amount: number): ValidatorFn {
    return function(control: AbstractControl): ValidationErrors {
      if (control.value % amount) {
        return new ValidationMessagesError(
          'incrementError',
          null,
          `Amount must be an increment of $${amount}`
        );
      }
      return null;
    };
  }
}
