import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, firstValueFrom } from 'rxjs';
import { share } from 'rxjs/operators';

import { Environment } from './environment';
import { brandConfiguration, brandRouting } from './environment.models';
import { PlatformConfigFeatures } from '@core/configuration/configuration.service';
import { SessionStorageObjects } from '@core/session-storage/session-storage.service';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
  private obs: Promise<Observable<Environment>>;
  private environmentName: string = document
    .querySelector('meta[name="environment"]')
    ?.getAttribute('content');
  private baseURL: string = window.location.origin;

  constructor(private http: HttpClient) {
    let brands = brandConfiguration;

    if (this.baseURL.includes('localhost')) {
      this.baseURL = 'https://dev02-apply.lle.risecredit.com';
     // this.baseURL = 'https://dev02-ams.lle.elastic.com/';
      this.environmentName = 'DEV02';
    }

    this.obs = new Promise<string>((resolve, reject) => {
      for (const [key, value] of Object.entries(brands)) {
        if (this.baseURL.includes(key)) {
          resolve(value);
          this.loadStyle(key);
          this.loadRouting(key);
          return;
        }
      }
      resolve('ElasticUI');
    }).then(value => {
      return http
        .get<Environment>(
          `${this.baseURL}/originations/${this.environmentName}/appconfigapi/api/UIConfiguration?path=${value}`,
          {
            headers: new HttpHeaders({
              'Content-Type': 'application/json',
              'Api-Version': 'v2'
            }),
            observe: 'body'
          }
        )
        .pipe(share());
    });
  }

  public getEnvironment(): Promise<Observable<Environment>> {
    return this.obs;
  }

  private loadStyle(brand: string): void {
    const head = document.getElementsByTagName('head')[0];

    const styleName = `${brand}.css`;
    const themeLink = document.getElementById('brand-theme') as HTMLLinkElement;
    const faviconName = `favicon-${brand}.ico`;
    const favIconLink = document.getElementById(
      'brand-favicon'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = document.createElement('link');
      style.id = 'brand-theme';
      style.rel = 'stylesheet';
      style.href = styleName;

      head.appendChild(style);
    }
    if (favIconLink) {
      favIconLink.href = faviconName;
    } else {
      const style = document.createElement('link');
      style.id = 'brand-favicon';
      style.rel = 'icon';
      style.type = 'image/x-icon';
      style.href = `/favIcons/${faviconName}`;

      head.appendChild(style);
    }
  }

  public async loadRouting(brand: string) {
    let brandId = brandRouting[brand];
    let applicationFlows = await firstValueFrom(
      this.http
      .get<any>(
        `${this.baseURL}/originations/${this.environmentName}/appconfigapi/api/PlatformConfig?brandId=${brandId}&featureName=${PlatformConfigFeatures.ApplicationFlow}`,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Api-Version': 'v2'
          }),
          observe: 'body'
        }
      )
    );
    let jsonObj = JSON.stringify(applicationFlows);
    sessionStorage.setItem(SessionStorageObjects.applicationFlows, jsonObj);
  }
}
