import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { ApplicationDataService } from '@application/application.service';
import { LoadingModalService } from '@application/loading-modal/loading-modal.service';
import { Select, TextInput } from '@elevate/ui-components';
import { UnderwritingApprovalFormGroup } from './underwriting-approval.form';
import { OtpService } from '@core/otp/otp.service';
import { SafeHtmlPipe, ValidationMessagesError } from '@elevate/forms';
import {
  ApplicationForm,
  MaritalStatusModel,
  MarriedSpouseModel
} from '@application/application';
import { ApplicationApi } from '@application/application.api';
import { Married } from './married-form/married.form';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailConfirmationDebugService } from './debug-hud/email-confirmation-debug.service';
import { UnderwritingDecisionEnum } from '@application/underwriting/underwriting.model';
import { DocumentService } from '@core/document/document.service';
import { DocumentApi } from '@core/document/document.api';
import { GoogleAnalytics } from '@core/google-analytics/googleanalytics.service';
import { CmsPageContentService } from '@core/cms/services/cms-page-content.service';
import { UnderwritingApprovalPageContent } from './underwriting-approval-content';
import { CurrencyPipe } from '@angular/common';
import { ApplicationFlowService } from '@core/application-flow/application-flow.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-underwriting-approval',
  templateUrl: './underwriting-approval.component.html',
  styleUrls: ['./underwriting-approval.component.scss'],
  providers: [UnderwritingApprovalFormGroup],
  encapsulation: ViewEncapsulation.None
})
export class UnderwritingApprovalComponent implements OnInit, OnDestroy {
  public message: string;
  public disclaimer: string;
  public pageSubTitle: string;
  public content: UnderwritingApprovalPageContent;
  public approvalAmount: number;
  public formattedApprovalAmount: string;
  public isDMuser: boolean;
  public email: string;
  public isWisconsin: boolean;
  public hideAdditionalQuestions: boolean;
  public isCounterOffer = false;
  public prequalOfferAmount: number;
  public emailVerified = false;
  public subHeader: string;
  public counterOffer: string;
  public counterOfferSubText: string;
  public messageText: string;
  public nextPage: string;

  public emailCodeConfig: TextInput = {
    id: 'emailConfirmationCodeInput',
    required: 'true',
    attributes: {
      'data-private': 'redact',
      'data-nid-target': 'emailConfirmationCode'
    },
    addGenesysCobrowseMaskingClass: 'true'
  };

  public maritalStatusConfig: Select = {
    id: 'maritalStatusSelect',
    disabled: 'false',
    value: '',
    visibleOptions: 2,
    options: [],
    attributes: {
      'data-nid-target': 'maritalStatus'
    }
  };

  constructor(
    public form: UnderwritingApprovalFormGroup,
    private applicationDataService: ApplicationDataService,
    private otpService: OtpService,
    private cmsPageContentService: CmsPageContentService,
    private loadingService: LoadingModalService,
    private applicationApi: ApplicationApi,
    private router: Router,
    private debugService: EmailConfirmationDebugService,
    private documentApi: DocumentApi,
    private documentService: DocumentService,
    private route: ActivatedRoute,
    private currencyPipe: CurrencyPipe,
    private applicationFlowService: ApplicationFlowService,
    public googleAnalytics: GoogleAnalytics
  ) {}

  ngOnInit(): void {
    const application = this.applicationDataService.getApplication();
    this.content = this.route.snapshot.data.cmsContent
      .approval as UnderwritingApprovalPageContent;
    this.maritalStatusConfig.options = [
      {
        value: MaritalStatusModel.Married,
        label: this.content.maritalStatus.maritalStatusSelect.optionMarried
      },
      {
        value: MaritalStatusModel.Unmarried,
        label: this.content.maritalStatus.maritalStatusSelect.optionNotMarried
      }
    ];
    this.nextPage = this.applicationFlowService.getContinuePath();
    this.maritalStatusConfig.placeholder = this.content.maritalStatus.maritalStatusSelect.placeholder;
    if (application.form.applicant.emails[0].status === 'Verified') {
      this.emailVerified = true;
    }
    if (application.form.applicant.residences[0].address.stateCode === 'WI') {
      this.isWisconsin = true;
      this.form.get('maritalStatus').enable();
    } else {
      this.form.get('maritalStatus').disable();
    }

    const firstName = application.form.applicant.identity.firstName;

    const applicationSubmitOffer = application.underwriting.steps.find(
      (decision: any) =>
        decision.name === 'ApplicationSubmit' && decision.status === 'Complete'
    );

    this.approvalAmount = applicationSubmitOffer.disposition.offer.line;
    this.googleAnalytics.setGoogleTagManagerVariables({
      approved_amount: this.approvalAmount
    });
    this.email = application.form.applicant.emails[0].address;
    this.messageText = this.getMessageText();

    if (
      applicationSubmitOffer.disposition.type ===
      UnderwritingDecisionEnum.CounterOffer
    ) {
      this.isCounterOffer = true;
      const prequalOffer = application.underwriting.steps.find(
        (decision: any) =>
          decision.name === 'Prequal' && decision.status === 'Complete'
      );
      this.prequalOfferAmount =
        application.form?.shortAppOffer?.creditLimit ||
        prequalOffer.disposition.offer.line;
      this.cmsPageContentService.updatePageTitle(
        `${this.content.counterOffer.header}`.replace('{firstName}', firstName)
      );
      this.getCounterOfferHeaders();
    } else {
      this.cmsPageContentService.updatePageTitle(
        `${this.content.approval.header}`.replace('{firstName}', firstName)
      );
      this.getOfferHeaders();
    }
  }

  public async onSubmit(): Promise<void> {
    this.form.showValidationErrors();
    if (!this.form.valid) {
      return;
    }

    this.loadingService.open();
    const confirmationCode = this.form.get('confirmationCode').value;
    let application: ApplicationForm = {};
    try {
      await lastValueFrom(
        this.otpService.verifyEmailCode({ otp: confirmationCode })
      );
      if (this.isWisconsin) {
        application = {
          applicant: {
            marital: this.constructMaritalObject()
          }
        };

        await lastValueFrom(this.applicationApi.append(application));
      }

      this.router.navigate([this.nextPage]);
    } catch {
      this.loadingService.close();
      this.setConfirmationCodeInvalidError();
    }
  }

  public async resendConfirmationCode(): Promise<void> {
    this.loadingService.open();

    const requestPayload = {
      channel: 'Email',
      brand: this.applicationDataService.getApplication().brand
    };
    try {
      await lastValueFrom(this.otpService.sendCode(requestPayload));
      this.loadingService.close();
    } catch {
      this.loadingService.close();
    }
  }
  public skipEmailVerification(): void {
    this.router.navigate([this.nextPage]);
  }

  private setConfirmationCodeInvalidError(): void {
    this.form
      .get('confirmationCode')
      .setErrors(
        new ValidationMessagesError(
          'verificationCode',
          null,
          this.content.validationMessages.confirmationCodeNotValid
        )
      );
  }

  public showAdditionalQuestions(value): void {
    this.hideAdditionalQuestions =
      value === MaritalStatusModel.Unmarried ? false : true;
  }

  public async populateConfirmationCode(): Promise<void> {
    await lastValueFrom(this.debugService.getConfirmationCode()).then(val => {
      this.form.get('confirmationCode').setValue(val.body.otpCode);
    });
  }

  public downloadNoaa = async (): Promise<void> => {
    const documentName = `noaa_${Date.now()}.pdf`;
    const blob: Blob = await lastValueFrom(this.documentApi.get('noaa'));
    this.documentService.download(blob, documentName);
  };

  private constructMaritalObject(): MarriedSpouseModel {
    const maritalValue = this.form.get('maritalStatus').value;

    const maritalResponse: MarriedSpouseModel = {
      status: maritalValue
    };
    if (this.form.get('maritalStatus').value === MaritalStatusModel.Married) {
      const married: Married = this.form.get('married').value;

      maritalResponse.spouseFirstName = married.spouseFirstName;
      maritalResponse.spouseLastName = married.spouseLastName;
      maritalResponse.spouseEmailAddress = this.form.get(
        'married.spouseEmailAddress'
      ).value;
    }
    return maritalResponse;
  }

  public ngAfterViewInit(): void {
    const anchorElements = document.getElementsByClassName(
      'ecl-font-link-light-bg'
    );

    const allanchorElements: Element[] = Array.from(anchorElements);

    for (let item of allanchorElements) {
      if (item.innerHTML.includes('Notice of Action')) {
        item.addEventListener('click', this.downloadNoaa);
      }
    }
  }
  public ngOnDestroy(): void {
    if (this.isCounterOffer) {
      const anchorElements = document.getElementsByClassName(
        'ecl-font-link-light-bg'
      );

      const allanchorElements: Element[] = Array.from(anchorElements);

      for (let item of allanchorElements) {
        if (item.innerHTML.includes('Notice of Action')) {
          item.removeEventListener('click', this.downloadNoaa);
        }
      }
    }
  }

  private getCounterOfferHeaders(): void {
    const formattedPrequalAmount = this.currencyPipe.transform(
      this.prequalOfferAmount,
      '$',
      false,
      '1.0-0'
    );
    const formattedApprovalAmount = this.currencyPipe.transform(
      this.approvalAmount,
      '$',
      false,
      '1.0-0'
    );
    this.formattedApprovalAmount = formattedApprovalAmount;

    this.subHeader = `${this.content.counterOffer.subHeader}`
      .replace('{prequalOfferAmount}', formattedPrequalAmount)
      .replace(/{approvalAmount}/g, formattedApprovalAmount);

    this.counterOfferSubText = `${this.content.counterOffer.subText}`
      .replace('{prequalOfferAmount}', formattedPrequalAmount)
      .replace(/{approvalAmount}/g, formattedApprovalAmount);
  }

  private getOfferHeaders(): void {

    const formattedApprovalAmount = this.currencyPipe.transform(
      this.approvalAmount,'$',
      false,
      this.content.approval.decimalValue
    );
    this.subHeader = `${this.content.approval.subHeader}`.replace(
      /{approvalAmount}/g,
      formattedApprovalAmount
    );
  }

  private getMessageText(): string {
    return this.content.content.text.replace('{email}', this.email);
  }
}
